<template>
  <b-container class='mt-4'>
    <b-row>
      <b-col cols='11'>
        <h4>SFTP User Details</h4>
      </b-col>
      <b-col>
        <b-button
          :disabled='loading'
          variant='primary'
          @click='createConfigs'
          class='float-right'>
          <feather type='save'></feather>
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if='loading'>
      <b-col cols='12'>
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <SFTPRemoteServerDetails v-else :editable='true' :configs='configs'
                             @form-validation='handleFormValidation($event)'></SFTPRemoteServerDetails>
  </b-container>
</template>

<script>
import SFTPRemoteServerDetails from '@/views/SFTP/RemoteServers/Details.vue';

export default {
  name: 'SFTPRemoteServerCreate',
  components: { SFTPRemoteServerDetails },
  data: () => ({
    loading: false,
    invalidForm: true,
    configs: {
      connection_key: null,
      hostname: null,
      port: null,
      username: null,
      use_socks_proxy: true,
      login_type: 'password',
      password: null,
      ssh_key: null,
      ssh_password: null,
    },
  }),
  methods: {
    async createConfigs() {
      if (this.invalidForm) {
        this.$noty.error('Invalid Form! Please fill out all required fields');
        return Promise.resolve();
      }
      this.loading = true;
      return this.$store.dispatch('Sftp/RemoteServer/createRemoteServerConfig', { data: this.configs })
        .then(result => {
          this.$router.push(`/sftp/remote-servers/${result.data.uuid}`);
          this.$noty.success('Remote server created successfully');
        })
        .catch(err => {
          this.$noty.error(err.message);
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleFormValidation(invalid) {
      this.invalidForm = invalid;
    },
  },
};
</script>
